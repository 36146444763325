@import url(https://fonts.googleapis.com/css2?family=Marcellus&family=Quicksand&display=swap);
:root {
  --background: rgb(238, 238, 238);
  --base-text: #1f2827;
  --light-text: #ddf0ed;
  --medium-gray: #7b8687;
  --green-accent: #47684a;
  --medium-green-accent: #709172;
  --light-green-accent: #b7dfba;
  --red-accent: #b4746c;
  --light-red-accent: #dbaca6;
  --light-blue: #a0b8d1;
  --blue-accent: #6294c8;
}

html,
body {
  position: relative;
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: rgb(238, 238, 238);
  background-color: var(--background);
  font-family: 'Marcellus', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  transition: all 0.5s ease;
}

li {
  list-style-type: none;
}

h1 {
  font-size: 2.5em;
}

h2 {
  margin: 2em auto;
}

h2,
h3,
h4,
h5 {
  font-size: 1.2em;
}

/* Utility Classes */

.container {
  max-width: 85%;
  margin: auto;
}

.center {
  text-align: center;
}

/* Styles text colors */
.base-text {
  color: #1f2827;
  color: var(--base-text);
}

.green-text {
  color: #47684a;
  color: var(--green-accent);
}

.light-text {
  color: #ddf0ed;
  color: var(--light-text);
}

.light-link {
  color: #ddf0ed;
  color: var(--light-text);
}

.quicksand {
  font-family: 'Quicksand', sans-serif;
}

.logo {
  font-size: 4em;
}

/* Give shape to all page links */
.link {
  padding: 5px 10px;
  border-radius: 15px;
}

/* Style all links with green hover effect */
.green-link:hover {
  background-color: #47684a;
  background-color: var(--green-accent);
  color: rgb(238, 238, 238);
  color: var(--background);
}

/* Style all links with light hover effect */
.light-link:hover {
  background-color: rgb(238, 238, 238);
  background-color: var(--background);
  color: #47684a;
  color: var(--green-accent);
}

.flex-item {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

/* Media queries for smaller screens */

@media screen and (max-width: 992px) {
  h1 {
    font-size: 2em;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 85%;
  }

  .footer-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

